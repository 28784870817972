<template>
    <div class="fill-height">
        <v-container>
            <auth-header title="Login"/>
            <form class="auth-form mt-8" @submit.prevent="send">
                <text-field label="Email or Username"
                            :error-messages="errors.email"
                            v-model="email"
                            class="mb-5"

                />
                <text-field label="Password"
                            type="password"
                            :error-messages="errors.password"
                            v-model="password"
                            class="mb-5"
                            v-on:keyup.enter="handleLogin"
                />
                <div class="text-right">
                    <router-link to="/forget-password" class="link">
                        <span class="text-caption">Forget Password?</span>
                    </router-link>
                </div>
                <turnstile-captcha explicit-render @verified="captchaToken = $event" />
                <span v-if="errors.captcha_token" class="red--text"><strong>* </strong>
                    {{ errors.captcha_token[0] }}
                </span>

                <btn-large class="btn-accent mt-8"
                           @click="handleLogin"
                           text="Login"/>
                <div class="text-caption text-center mt-8">
                    <span>You don’t have an account?&nbsp;</span>
                    <router-link to="/sign-up"
                                 class="link error--text">
                        <span>Sign up</span>
                    </router-link>
                </div>
            </form>
        </v-container>
    </div>
</template>

<script>
import AuthHeader from '@/components/app/auth/AuthHeader';
import TextField from '@/components/app/form/TextField';
import {mapActions, mapMutations, mapState} from "vuex";
import SocialLinks from "@/components/app/auth/SocialLinks";
import StaticPageLinks from "@/components/app/common/StaticPageLinks";
import TurnstileCaptcha from '@/components/app/auth/TurnstileCaptcha';

export default {
    name: "Login",
    components: {
        StaticPageLinks,
        SocialLinks,
        AuthHeader,
        TextField,
        TurnstileCaptcha,
    },
    data: () => ({
        email: '',
        password: '',
        errors: {
            email: '',
            password: ''
        },
        captchaToken: null,
    }),
    computed: {
        ...mapState({
            user: state => state.user.user.data,
        })
    },
    methods: {
        handleLogin() {
            if (!this.captchaToken) {
                alert("Please complete the CAPTCHA");
                return;
            }
            const {email, password, captchaToken} = this;
            this.$auth.login({
                data: {
                    email,
                    password,
                    captchaToken
                },
                staySignedIn: true,
                fetchUser: true
            })
            .then((response) => {
                localStorage.setItem('email_verified', response.data.data.email_verified)
                this.$router.push({'name': 'admin.index'}).catch((e) => console.log(e))
            }, e => {
                this.errors = e.response.data.errors
            })
        },

        ...mapActions([
            'login',
        ]),
        ...mapMutations(['showSnackBar'])
    }
}
</script>
